import { Box, Button, Container, Modal, Typography } from "@mui/material";
import { useRef } from "react";
import { formatPrice } from "../../../../utils/Strings";
import { useReactToPrint } from "react-to-print";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FFFFFF",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  color: "#000000",
  overflow: "scroll",
  maxHeight: "80vh",
};

const defaultFontStyle = {
  color: "gray",
  fontWeight: "bold",
  fontSize: 10,
};
const flexBetweenStyle = {
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  flexDirection: "row",
};

export default function DSRModal(props) {
  const { open, handleClose, data } = props;
  const printableAreaRef = useRef(0);

  const handlePrintClick = useReactToPrint({
    content: () => printableAreaRef.current,
  });

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box sx={style}>
        <Box>
          <h3>Preview</h3>
          <Box
            ref={printableAreaRef}
            p={2}
            display="flex"
            flexDirection="column"
          >
            <Typography style={defaultFontStyle} textAlign="center">
              DAILY SALES REPORT
            </Typography>
            <br />
            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>DSR #</Typography>
              <Typography style={defaultFontStyle}>{data?.code}</Typography>
            </Box>

            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Report Date</Typography>
              <Typography style={defaultFontStyle}>
                {data?.report_date || " "}
              </Typography>
            </Box>

            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Report Time</Typography>
              <Typography style={defaultFontStyle}>
                {data?.report_time || " "}
              </Typography>
            </Box>

            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Prepared By</Typography>
              <Typography style={defaultFontStyle}>
                {data?.prepared_by || " "}
              </Typography>
            </Box>
            <br />
            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Cashier Name</Typography>
              <Typography style={defaultFontStyle}>
                {data?.cashier_name || " "}
              </Typography>
            </Box>
            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Cash on hand</Typography>
              <Typography style={defaultFontStyle}>
                {formatPrice(data?.cash_on_hand)}
              </Typography>
            </Box>
            <br />
            {/* <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Gross Sales</Typography>
              <Typography style={defaultFontStyle}>
                {formatPrice(data?.gross_sale)}
              </Typography>
            </Box>

            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Tax</Typography>
              <Typography style={defaultFontStyle}>
                {formatPrice(data?.tax)}
              </Typography>
            </Box>

            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>
                Net Sales After Tax
              </Typography>
              <Typography style={defaultFontStyle}>
                {formatPrice(data?.net_sales_after_tax)}
              </Typography>
            </Box>

            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Discount</Typography>
              <Typography style={defaultFontStyle}>
                {formatPrice(data?.discount_amount)}
              </Typography>
            </Box>

            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>
                Total Sales
              </Typography>
              <Typography style={defaultFontStyle}>
                {formatPrice(data?.net_sales_after_discount)}
              </Typography>
            </Box>

            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Over / (Short)</Typography>
              <Typography style={defaultFontStyle}>
                {formatPrice(data?.short)}
              </Typography>
            </Box> */}
            {/* <br />
            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Category Sales:</Typography>
            </Box>

            {data?.sold_products_by_category &&
              Object.keys(data?.sold_products_by_category).map((key) => (
                <Box style={flexBetweenStyle}>
                  <Typography style={defaultFontStyle}>{key}</Typography>
                  <Typography style={defaultFontStyle}>
                    {formatPrice(data?.sold_products_by_category[key])}
                  </Typography>
                </Box>
              ))} */}
            <br />
            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>
                Per Payment Method Sales:
              </Typography>
            </Box>

            {data?.per_payment_method &&
              Object.keys(data?.per_payment_method).map((key) => (
                <Box style={flexBetweenStyle}>
                  <Typography style={defaultFontStyle}>{key}</Typography>
                  <Typography style={defaultFontStyle}>
                    {formatPrice(data?.per_payment_method[key])}
                  </Typography>
                </Box>
              ))}
            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Over/Short</Typography>
              <Typography style={defaultFontStyle}>
                {formatPrice(data?.over_short)}
              </Typography>
            </Box>
            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Cost of Goods</Typography>
              <Typography style={defaultFontStyle}>
                {formatPrice(data?.cost_of_goods_sold)}
              </Typography>
            </Box>
            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Total Discount</Typography>
              <Typography style={defaultFontStyle}>
                {formatPrice(data?.discount_amount)}
              </Typography>
            </Box>
            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Earnings</Typography>
              <Typography style={defaultFontStyle}>
                {formatPrice(data?.earnings)}
              </Typography>
            </Box>

            <br />
            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Category Sales:</Typography>
            </Box>

            {data?.sold_products_by_category &&
              Object.keys(data?.sold_products_by_category).map((key) => (
                <Box style={flexBetweenStyle}>
                  <Typography style={defaultFontStyle}>{key}</Typography>
                  <Typography style={defaultFontStyle}>
                    {formatPrice(data?.sold_products_by_category[key])}
                  </Typography>
                </Box>
              ))}

            <br />
            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Hourly Sales:</Typography>
            </Box>

            {data?.hourly_sales &&
              Object.keys(data?.hourly_sales).map((key) => (
                <Box style={flexBetweenStyle}>
                  <Typography style={defaultFontStyle}>{key}</Typography>
                  <Typography style={defaultFontStyle}>
                    {formatPrice(data?.hourly_sales[key])}
                  </Typography>
                </Box>
              ))}
            <br />
            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>Key Performance:</Typography>
            </Box>

            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>
                Avg. Transaction Value
              </Typography>
              <Typography style={defaultFontStyle}>
                {formatPrice(data?.total_transaction_value)}
              </Typography>
            </Box>
            <Box style={flexBetweenStyle}>
              <Typography style={defaultFontStyle}>
                Transaction Count
              </Typography>
              <Typography style={defaultFontStyle}>
                {data?.total_transaction_count}
              </Typography>
            </Box>
            <br />
            <Typography style={defaultFontStyle} textAlign="center">
              * * * Nothing Follows * * *
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            textAlign: "center",
            justifyContent: "space-between",
            alignSelf: "normal",
            display: "flex",
            p: 2,
          }}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handlePrintClick();
            }}
          >
            Print
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
